<template>
<b-card title="Analysis">
    <p>Get the stats for the last 3 Months</p>
    <b-form ref="form">
        <!-- Row Loop -->
        <b-row ref="row">
            <!-- Item Name -->
            <b-col md="5">
                <b-form-group label="Start Date" label-for="item-name">
                    <b-form-datepicker id="startdatepicker-buttons" today-button reset-button close-button v-model="startDate" locale="en" />
                </b-form-group>
            </b-col>

            <!-- Cost -->
            <b-col md="5">
                <b-form-group label="End Date" label-for="item-name">
                    <b-form-datepicker id="enddatepicker-buttons" today-button reset-button close-button v-model="endDate" locale="en" />
                </b-form-group>
            </b-col>

            <!-- Remove Button -->
            <b-col md="2" class="mb-50">
                <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="outline-danger" class="mt-0 mt-md-2" @click="getRecords()">
                    <feather-icon icon="SearchIcon" class="mr-25" />
                    <span id="search">Search</span>
                </b-button>
            </b-col>
            <b-col cols="12">
                <hr>
            </b-col>
        </b-row>
        <b-row class="match-height" v-show="showRecords">
            <b-col lg="4" sm="12">
                <statistic-card-with-area-chart v-if="data.sentConsumption" icon="MessageCircleIcon" color="secondary" :statistic="data.sentConsumption.analyticsData.balance | formatNumber" statistic-title="SMS Sent" :chart-data="data.sentConsumption.series" />
            </b-col>
            <b-col lg="4" sm="12">
                <statistic-card-with-area-chart v-if="data.deliveryConsumption" icon="CheckCircleIcon" color="success" :statistic="data.deliveryConsumption.analyticsData.balance | formatNumber" statistic-title="SMS Delivered" :chart-data="data.deliveryConsumption.series" />
            </b-col>
            <b-col lg="4" sm="12">
                <statistic-card-with-area-chart v-if="data.failedConsumption" icon="ListIcon" color="danger" :statistic="data.failedConsumption.analyticsData.balance | formatNumber" statistic-title="SMS Failed" :chart-data="data.failedConsumption.series" />
            </b-col>
        </b-row>
        <b-row ref="inboxList" v-show="showRecords">
            <app-echart-stacked-area ref="stateChart" :option-data="option" />
        </b-row>
    </b-form>

</b-card>
</template>

<script>
import {
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BButton,
    BFormDatepicker,
    BCard,
    BTable,
    BFormCheckbox,
    BBadge,
    BInputGroupAppend,
    BFormSelect,
    BInputGroup,
    BPagination
} from 'bootstrap-vue'
import AppEchartStackedArea from '@core/components/charts/echart/AppEchartStackedArea.vue'
import moment from "moment";
import Ripple from 'vue-ripple-directive'
import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'
import $ from 'jquery'
import store from '@/store'
import numeral from "numeral";
import {
    kFormatter
} from '@core/utils/filter'
export default {
    components: {
        BForm,
        BFormGroup,
        BFormInput,
        BRow,
        BCol,
        BButton,
        BFormDatepicker,
        BCard,
        BTable,
        BFormCheckbox,
        BBadge,
        BInputGroupAppend,
        BFormSelect,
        BInputGroup,
        BPagination,
        AppEchartStackedArea,
        StatisticCardWithAreaChart,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            showRecords: false,
            endDate: "",
            startDate: "",
            data: {
                sentConsumption: {
                    series: [{
                        name: 'Sent',
                        data: [],
                    }, ],
                    analyticsData: {
                        balance: 0,
                        currency: ""
                    },
                },
                deliveryConsumption: {
                    series: [{
                        name: 'Delivered',
                        data: [],
                    }, ],
                    analyticsData: {
                        balance: 0,
                        currency: ""
                    },
                },
                failedConsumption: {
                    series: [{
                        name: 'Failed',
                        data: [],
                    }, ],
                    analyticsData: {
                        balance: 0,
                        currency: ""
                    },
                },
            },
            option: {
                xAxisData: [],
                series: [{
                        name: 'Sent',
                        type: 'line',
                        stack: 'Total',
                        areaStyle: {
                            color: '#84d0ff',
                        },
                        color: '#84d0ff',
                        showSymbol: false,
                        smooth: 0.4,
                        lineStyle: {
                            width: 0,
                        },
                        data: [],
                    },
                    {
                        name: 'Delivered',
                        type: 'line',
                        stack: 'Total',
                        areaStyle: {
                            color: '#28c76f',
                        },
                        color: '#28c76f',
                        showSymbol: false,
                        smooth: 0.4,
                        lineStyle: {
                            width: 0,
                        },
                        data: [],
                    },
                    {
                        name: 'Failed',
                        type: 'line',
                        stack: 'Total',
                        areaStyle: {
                            color: '#FF2447',
                        },
                        color: '#FF2447',
                        showSymbol: false,
                        smooth: 0.4,
                        lineStyle: {
                            width: 0,
                        },
                        data: [],
                    },
                ],
            },
            optionData: [],
            totalSent: 0,
            totalDelivered: 0,
            totalFailed: 0
        }
    },
    created() {
        this.option.xAxisData = this.optionData;
    },
    methods: {
        kFormatter,
        getRecords() {
            let vm = this
            if (this.endDate == "" || this.startDate == "") {
                this.$swal({
                    title: 'Error!',
                    text: ' You need to select the date',
                    icon: 'error',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                })
            } else {
                $('#search').html('Please wait...');
                $.get({
                    url: store.state.rootUrl + 'account/v1/view/outbox_stats',
                    type: "get",
                    async: true,
                    data: {
                        start: vm.startDate,
                        end: vm.endDate
                    },
                    crossDomain: true,
                    headers: {
                        "Content-Type": "application/json",
                        "X-Requested-With": "XMLHttpRequest",
                        "X-Authorization-Key": vm.$cookies.get("token"),
                    },
                    success: function (response, status, jQxhr) {
                        $('#search').html('Search');
                        if (response.data.code != 200) {
                            vm.$swal({
                                title: 'Error!',
                                text: response.data.message,
                                icon: 'error',
                                customClass: {
                                    confirmButton: 'btn btn-primary',
                                },
                                buttonsStyling: false,
                            })
                        } else {

                            let result = response.data.data.data;
                            var arrayDate = []
                            var arraySent = []
                            var arrayDelivered = []
                            var arrayFailed = []
                            var totalSent = 0
                            var totalDelivered = 0
                            var totalFailed = 0
                            for (var i = 0; i < result.length; i++) {
                                vm.option.xAxisData.push(moment(result[i].created_at).format('DD/MM'))
                                vm.option.series[0].data.push(result[i].sent)
                                totalSent = totalSent + Number(result[i].sent)
                                vm.option.series[1].data.push(result[i].delivered)
                                totalDelivered = totalDelivered + Number(result[i].delivered)
                                vm.option.series[2].data.push(result[i].failed)
                                totalFailed = totalFailed + Number(result[i].failed)
                            }
                            
                           
                            vm.data.sentConsumption.series[0].data = arraySent

                            vm.data.deliveryConsumption.series[0].data = arrayDelivered

                            vm.data.failedConsumption.series[0].data = arrayFailed
                            vm.totalSent = totalSent
                            vm.data.sentConsumption.analyticsData.balance = totalSent
                            vm.totalDelivered = totalDelivered
                            vm.data.deliveryConsumption.analyticsData.balance = totalDelivered
                            vm.totalFailed = totalFailed
                            vm.data.failedConsumption.analyticsData.balance = totalFailed
                            setTimeout(vm.showRecords = true, 3000);

                            console.log(JSON.stringify(vm.option))
                        }
                    },
                    error: function (jQxhr, status, error) {
                        if (jQxhr.responseJSON.data.code == 401 || jQxhr.responseJSON.data.code == 500 || jQxhr.responseJSON.data.code == 403 || jQxhr.responseJSON.data.code == 404) {
                            vm.$cookies.remove("token");
                            vm.$cookies.remove("clientData");
                            // Redirect to login page
                            vm.$router.push({
                                name: 'login'
                            })
                            vm.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: jQxhr.responseJSON.data.message,
                                    icon: 'EditIcon',
                                    variant: 'failed',
                                },
                            })
                        }
                        $('#search').html('Search');
                    }
                });

            }

        }
    },
    filters: {
        formatNumber(value) {
            if (!value) return 0
            return numeral(value).format("0,0");
        },

    }
}
</script>
