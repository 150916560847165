<template>
    <b-card no-body>
    
        <b-card-body class="pb-0">
            <b-avatar class="mb-1" :variant="`light-${color}`" size="45">
                <feather-icon size="21" :icon="icon" />
            </b-avatar>
            <div class="truncate">
                <b-spinner small v-show="loader" /><small v-show="loader"> Loading...<br /> </small>
                <h2 class="mb-25 font-weight-bolder" v-show="!loader">
                    {{statisticCurrency}} {{ statistic }} <br /> <small>Threshold: {{statisticCurrency}} {{statisticThreshold}}</small>
                    <br /> <small>Bonus: {{statisticCurrency}} {{statisticBonus}}</small>
                </h2>
                <span>{{ statisticTitle }}</span>
    
            </div>
            <div class="demo-inline-spacing">
            <b-button v-show="buttonShow" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary" @click="goToBulkRates">
            View SMS Units
            </b-button>
            <b-button v-show="buttonShow" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="editAmount">
                Edit Threshold
            </b-button>
        </div>
        </b-card-body>
        
        <br />
        <!-- 
        <vue-apex-charts
          type="area"
          height="100"
          width="100%"
          :options="chartOptionsComputed"
          :series="chartData"
        /> -->
    
        <b-modal v-model="edithreshhold" cancel-variant="outline-secondary" ok-title="Update" cancel-title="Close" centered title="Edit Threshold" @ok="edithreshholdData">
            <b-alert v-height-fade.appear :show="dismissCountDown" dismissible fade variant="danger" class="mb-0" @dismiss-count-down="countDownChanged">
                <div class="alert-body">
                    <span>{{errorMessage}}</span>
                </div>
            </b-alert>
            <b-form>
                <b-form-group label="Threshold Amount" label-for="threshamount">
                    <b-form-input type="number" id="threshamount" placeholder="1000" v-model="amount" />
                </b-form-group>
            </b-form>
        </b-modal>
    
    </b-card>
</template>

<script>
import {
    BCard,
    BCardBody,
    BAvatar,
    BSpinner,
    BButton,
    BAlert,
    BForm,
    BFormGroup,
    BFormInput
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import {
    $themeColors
} from '@themeConfig'
import {
    areaChartOptions
} from './chartOptions'
import Ripple from 'vue-ripple-directive'
import {
    heightFade
} from '@core/directives/animations'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import $ from 'jquery'
export default {
    components: {
        VueApexCharts,
        BCard,
        BCardBody,
        BAvatar,
        BSpinner,
        BButton,
        BAlert,
        BForm,
        BFormGroup,
        BFormInput
    },
    directives: {
        Ripple,
        heightFade
    },
    props: {
        icon: {
            type: String,
            required: true,
        },
        loader: {
            type: Boolean,
            default: false,
        },
        buttonShow: {
            type: Boolean,
            default: false,
        },
        statistic: {
            type: [Number, String],
            required: true,
        },
        statisticThreshold: {
            type: [Number, String]
        },
        statisticBonus: {
            type: [Number, String]
        },
        statisticCurrency: {
            type: String,
            default: '',
        },
        statisticTitle: {
            type: String,
            default: '',
        },
        color: {
            type: String,
            default: 'primary',
        },
        chartData: {
            type: Array,
            default: () => [],
        },
        chartOptions: {
            type: Object,
            default: null,
        },
    },
    computed: {
        chartOptionsComputed() {
            if (this.chartOptions === null) {
                const options = JSON.parse(JSON.stringify(areaChartOptions))
                options.theme.monochrome.color = $themeColors[this.color]
                return options
            }
            return this.chartOptions
        },
    },
    data() {
        return {
            errorMessage: "",
            amount: 1000,
            dismissSecs: 5,
            dismissCountDown: 0,
            edithreshhold: false,
            isLoading: false,
        }
    },
    methods: {
        editAmount() {
            this.edithreshhold = true
        },
        goToBulkRates() {
            this.$router.push({
                name: 'bulk-rate-card'
            });
        },
        countDownChanged(dismissCountDown) {
            this.dismissCountDown = dismissCountDown
        },
        edithreshholdData() {
            let vm = this
            $.post({
                url: store.state.rootUrl + 'account/v1/configure/edit_threshold',
                post: "POST",
                headers: {
                    "X-Authorization-Key": vm.$cookies.get("token"),
                    "X-Requested-With": "XMLHttpRequest",
                    "Content-Type": "application/json",
                },
                data: JSON.stringify({
                    clientId: vm.$cookies.get("clientData").clientId,
                    thresholdAmount: vm.amount,
                    sendAlert: 1,
                }),
                success: function(response, status, jQxhr) {
                    vm.isLoading = false
                    if (response.data.code != 200) {
                        vm.dismissCountDown = vm.dismissSecs
                        vm.showDismissibleAlert = true
                        vm.errorMessage = response.data.message

                    } else {
                        vm.$toast({
                            component: ToastificationContent,
                            props: {
                                title: response.data.message,
                                icon: 'EditIcon',
                                variant: 'success',
                            },
                        })
                    }

                },
                error: function(jQxhr, status, error) {
                    vm.isLoading = false
                    vm.dismissCountDown = vm.dismissSecs
                    var statustext = jQxhr.responseJSON.statusDescription;
                    vm.showDismissibleAlert = true;
                    if (!statustext) {
                        vm.errorMessage = "An error occurred. Try again later.";
                    } else {
                        vm.errorMessage = statustext + " " + errorMessage;
                    }
                }
            });
        }
    }
}
</script>
